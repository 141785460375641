import { getRequest, postBodyRequest } from '@/utils/axios'

// 所有虚拟号码分页
export function virtualNumberPage (params) {
  return postBodyRequest('/trans/virtualNumber/virtual-number/page', params)
}
// 订单的虚拟号码
export function virtualOrderList (params) {
  return getRequest('/trans/virtualNumber/virtual-number/list', params)
}

// 获取绑定的电话
export function bindNumberInfo (params) {
  return postBodyRequest('/trans/virtualNumber/bind-number/info', params)
}

// 虚拟号 AXN 模式绑定 先获取 获取绑定的电话 返回内容 才能使用该接口
export function bindAx (params) {
  return postBodyRequest('/trans/virtualNumber/bind-ax', params)
}

// 虚拟号 解绑
export function unbindAx (params) {
  return getRequest('/trans/virtualNumber/unbind-ax', params)
}


