<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form :model="searchForm" :labelCol="{ flex: '100px' }" layout="inline">
        <a-form-item label="" :wrapperCol="{ flex: 'auto' }">
          <a-input  allowClear v-model:value="searchForm.orderId" placeholder="订单号" />
        </a-form-item>
        <a-form-item label="" :wrapperCol="{ flex: 'auto' }">
          <a-input  allowClear v-model:value="searchForm.mobile" placeholder="电话号码" />
        </a-form-item>
        <a-space>
          <a-button type="primary" @click="onSearch">查询</a-button>
          <a-button @click="resetSearch">重置</a-button>
        </a-space>
      </a-form>
    </template>
    <template #toolbarLeft>
      <a-button  type="primary" @click="onClick()">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        手动绑定虚拟号
      </a-button>
    </template>
    <a-table :columns="[sortColumn, ...columns]" :rowKey="(record, index) => { return index }" :data-source="listData"
      :pagination="pagination" :loading="loading" bordered @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
       <template #isUnbind="{ record }">
          <span :class="record.isUnbind.value == 0 ? 'isUnbindLabel' : ''"> {{record.isUnbind.label}}</span>
       </template>
      <template #operation="{ record }">
        <a-popconfirm title="确定解绑?" @confirm="unBindNum(record.id)">
          <a v-if="record.isUnbind && record.isUnbind.value === 0">手动解绑</a>
        </a-popconfirm>
      </template>
      <template #Status="{ record }">
        <span
          :class="[(record.vehicleTransStatus.value === 0) ? 'f1ab18' : '', (record.vehicleTransStatus.value === 1) ? 'd89f8' : '', (record.vehicleTransStatus.value === 2) ? 'cb32f' : '', (record.vehicleTransStatus.value === 3) ? 'ee5c23' : '', (record.vehicleTransStatus.value === 4) ? 'lv' : '', (record.vehicleTransStatus.value === 5) ? 'e3e3e3' : '']">
          {{ record.vehicleTransStatus.label }}</span>
      </template>
    </a-table>
  </suy-table>
  <a-modal destroyOnClose @cancel="fromData={},msgShow = true" title="手动绑定虚拟号"  width="40%" :maskClosable="false" :footer="null" v-model:visible="visible" >
    <a-form ref="fromRef" :model="fromData" :rules="rules">
      <a-row :gutter="2">
        <a-col :span="12">
          <a-form-item label="使用场景" name="scene">
            <a-select v-model:value="fromData.scene" class="ransport_search_input" allowClear>
              <a-select-option value=1>送车</a-select-option>
              <!-- <a-select-option value=2>取车</a-select-option> -->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="订单号" name="orderId">
            <a-input v-model:value="fromData.orderId" @change="changeValue" placeholder="请输入要绑定的订单号" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item v-if="fromData.salesman" label="业务员">
            {{fromData.salesman}}
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item  v-if="fromData.bindNumber"  label="绑定信息">
            {{fromData.name}} {{fromData.bindNumber}}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item >
            <a-space>
              <a-button type="primary" v-show="msgShow" @click="getBindOrderMsg()" :loading="loading">
                获取绑定信息
              </a-button>
              <a-button type="primary" v-show="!msgShow" @click="bindNum()" :loading="loading">
                确定绑定
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal >
</template>
<script>
import { toRefs, reactive, onMounted } from 'vue'
import SuyTable from '@/components/SuyTable'
import { message } from 'ant-design-vue'
import { CloudFilled } from '@ant-design/icons-vue'
import { virtualNumberPage as page, bindAx, unbindAx, bindNumberInfo } from '@/api/virtualNumber/virtualNumber'
export default {
  components: {
    SuyTable,
    CloudFilled
  },
  setup () {
    const state = reactive({
      fromRef: null,
      fromData: {},
      loading: false,
      visible: false,
      msgShow: true,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
      },
      rules: {
        scene: { value: { required: true, message: '请选择分类', trigger: 'change', type: 'number' } },
        orderId: { required: true, message: '请输入订单号', trigger: 'blur' }
      },
      mirrorSearchForm: {},
      listData: [],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '订单号',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '绑定类型',
          dataIndex: 'bindType.label',
          key: 'bindType.label'
        },
        {
          title: '使用场景',
          dataIndex: 'sceneType.label',
          key: 'sceneType.label'
        },
        {
          title: '真实号码',
          dataIndex: 'bindNumberA',
          key: 'bindNumberA'
        },
        {
          title: '虚拟号',
          dataIndex: 'middleNumber',
          key: 'middleNumber'
        },
        {
          title: '分机号',
          dataIndex: 'ext',
          key: 'ext'
        },
        {
          title: '绑定人',
          dataIndex: 'creator',
          key: 'creator'
        },
        {
          title: '绑定时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '解绑状态',
          dataIndex: 'isUnbind.label',
          key: 'isUnbind.label',
          slots: {
            customRender: 'isUnbind'
          }
        },
        {
          title: '解绑人',
          dataIndex: 'unbindPerson',
          key: 'unbindPerson'
        },
        {
          title: '解绑时间',
          dataIndex: 'unbindTime',
          key: 'unbindTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      form: {
        paidAmt: 0,
        officeOrgId: '',
        vehicleId: ''
      },
      fileList: [],
      fileUid: '',
      orderId: ''
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetSearch = () =>{
      state.pagination.current = 1
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await page({
        current: state.pagination.current,
        size: state.pagination.pageSize,
        orderId: state.searchForm.orderId,
        bindNumberA: state.searchForm.mobile,
      })
      state.listData = records

      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    const getBindOrderMsg = async () => {
      state.fromData.sceneType = Number(state.fromData.scene)
      bindNumberInfo(state.fromData)
        .then(res => {
          if (res.code === 10000) {
            state.fromData.salesman = res.data.salesman
            state.fromData.bindNumber = res.data.bindNumber
            state.fromData.name = res.data.name
            state.msgShow = false
          }
      })
    }
    const bindNum = () => {
      state.fromRef.validate()
        .then(() => {
          state.fromData.bindNumberA = state.fromData.bindNumber
          bindAx(state.fromData).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.visible = false
              state.fromData = {}
              loadData()
            }
          }).catch(err => { console.log('错误', err) }).finally(() => { state.confirmLoading = false })
        }).catch((error) => {
          console.log(error) // 正则错误提示
        })
    }
    const unBindNum = (id) => {
      unbindAx({id: id}).then(res => {
        if (res.code === 10000) {
          message.success('解绑成功')
          loadData()
        }
      }).catch(err => { console.log('错误', err) }).finally(() => { })
    }
    onMounted(()=>{
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const onClick = () => {
      state.visible = true
    }
    const changeValue = (val) => {
     state.msgShow = true
    
    }
    const handleTableChange = page => {
      state.pagination.current = page.current
      state.pagination.pageSize = page.pageSize
      loadData()
    }
    return {
      ...toRefs(state),
      loadData,
      onSearch,
      bindNum,
      unBindNum,
      handleTableChange,
      getBindOrderMsg,
      resetSearch,
      onClick,
      changeValue
    }
  }
}
</script>
